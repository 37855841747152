<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center bg-clay overflow-auto">
        <div 
            v-if="!user"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-clay-light text-white p-8 flex flex-col rounded-t-lg md:rounded-none">
                <h2 class="text-2xl font-normal mb-2 leading-tight">{{$t('auth.signUp')}}</h2>
                <p>{{$t('auth.welcomeNewUser')}}</p>
            </div>
            
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="signUp()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        <!-- <h2 class="text-white">Error</h2> -->
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        :label="$t('auth.email')"
                        name="email"
                        :placeholder="$t('auth.email')"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                        :autofocus="true"
                    />
                    
                    <InputSingleline 
                        v-model="password"
                        :label="$t('auth.password')"
                        name="password"
                        :placeholder="$t('auth.password')"
                        class="w-full "
                        type="password"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        {{$t('auth.signUp')}}
                    </button>
                </form>
                
                <div class="text-center flex flex-col">
                    <router-link :to="{ name: 'signin'}">
                        {{$t('auth.regsiteredAlready')}}
                    </router-link>
                </div>
            </div>
        </div>
        <div 
            v-else-if="user"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-clay-light text-white p-8 flex flex-col rounded-t-lg md:rounded-none">
                <div class="flex-grow">
                    <h2 class="text-2xl font-normal mb-2">{{$t('auth.confirmEmail')}}</h2>
                    <p>{{$t('auth.confirmEmailText')}}</p>
                </div>
            </div>
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="confirmSignUp()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        <!-- <h2 class="text-white">Error</h2> -->
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        :label="$t('auth.email')"
                        name="email"
                        :placeholder="$t('auth.email')"
                        class="w-full -mt-4"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="confirmCode"
                        :label="$t('auth.verificationCode')"
                        name="confirmCode"
                        :placeholder="$t('auth.verificationCode')"
                        class="w-full"
                        :required="true"
                        :autofocus="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit mb-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        {{$t('auth.confirm')}}
                    </button>
                </form>
                
                <div class="text-center flex flex-col">
                    <a 
                        class="cursor-pointer" 
                        @click="resendSignUp()">
                        
                        {{$t('auth.resendConfirmCode')}}
                    </a>
                </div>
            </div>
        </div>
        
        <router-link :to="{ name: 'www'}">
            {{$t('auth.backToWebsite')}}
        </router-link>
    </div>
</template>

<script>

import AuthMixins from '@/mixins/AuthMixins';

export default {
    data() {
        return {
            email: null,
            password: null,
            error: null,
            user: null,
            confirmCode: null,
            loading: false,
        }
    },
    
    mixins: [
        AuthMixins,
    ],
    
    mounted(){
        if (
            this.$route && 
            this.$route.query && 
            this.$route.query.promoCode || this.$route.query.promocode ) {
            
            localStorage.promoCode = this.$route.query.promoCode || this.$route.query.promocode;
        }
        // https://localhost:8080/signup?promoCode=sellit2021
    }
}
</script>
